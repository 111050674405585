import React, { useRef, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';

interface ModelProps {
    url: string;
    position: [number, number, number];
    scale: number;
    rotation: [number, number, number];
    isClickable?: boolean;
    onClick?: () => void;
    onPointerOver?: (event: ThreeEvent<PointerEvent>) => void;
    onPointerOut?: (event: ThreeEvent<PointerEvent>) => void;
}

const Model: React.FC<ModelProps> = ({ url, position, scale, rotation, isClickable, onClick, onPointerOver, onPointerOut }) => {
    const ref = useRef<THREE.Group>(null);
    const { scene } = useGLTF(url);

    const handlePointerOver = (event: ThreeEvent<PointerEvent>) => {
        if (isClickable) {
            document.body.style.cursor = 'pointer';
            onPointerOver?.(event);
        }
    };

    const handlePointerOut = (event: ThreeEvent<PointerEvent>) => {
        if (isClickable) {
            document.body.style.cursor = 'auto';
            onPointerOut?.(event);
        }
    };
    return (
        <group ref={ref} position={position} scale={scale} rotation={rotation}
            onClick={onClick}
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}>
            <primitive object={scene} />
        </group>
    );
}

export default Model;
