import { Environment, Grid, OrbitControls, PivotControls, Text3D } from '@react-three/drei';
import { Canvas, useThree, extend } from '@react-three/fiber';
import { LumaSplatsThree } from "@lumaai/luma-web";
import React, { Suspense, useEffect, useState, useCallback } from 'react';
import './app2React';
import Model from './Model';
import { ThreeEvent } from '@react-three/fiber';

extend({ LumaSplats: LumaSplatsThree });

function SceneSetup() {
	const { camera } = useThree();
	useEffect(() => {
		camera.position.set(5, 1, 1);
	}, [camera]);
	return null;
}

export function DemoReactThreeFiber() {
	const gridConfig = {
		gridSize: [10.5, 10.5],
		cellSize: 0.6,
		cellThickness: .5,
		cellColor: '#00b8ff',
		sectionSize: 3.3,
		sectionThickness: 1,
		sectionColor: '#00ff9f',
		fadeDistance: 25,
		fadeStrength: 1,
		followCamera: false,
		infiniteGrid: true
	}

	const [tooltip, setTooltip] = useState({ show: false, text: '', x: 0, y: 0 });
	let hoverTimeout: ReturnType<typeof setTimeout>;

	const handlePointerOver = useCallback((event: ThreeEvent<PointerEvent>) => {
		clearTimeout(hoverTimeout);
		const x = event.clientX + 15; // Offset to the right
		const y = event.clientY + 15; // Offset downwards
		setTooltip({ show: true, text: 'Discord', x, y });
	}, []);

	const handlePointerOut = useCallback(() => {
		hoverTimeout = setTimeout(() => {
			setTooltip({ show: false, text: '', x: 0, y: 0 });
		}, 100); // Delay to handle quick mouse movements
	}, []);

	const handleModelClick = useCallback(() => {
		window.open('https://discord.gg/GkrDdQPmBw', '_blank');
	}, []);


	return (
		<>
			<Canvas>
				<SceneSetup />
				<OrbitControls autoRotate={true} autoRotateSpeed={1} enableDamping={true} />
				<Suspense fallback={null}>
					<Environment background blur={0} files="assets/nightsky.hdr" />
				</Suspense>
				<Grid position={[0, -1, 0]} args={[10.5, 10.5]} {...gridConfig} renderOrder={-1} />
				<PivotControls anchor={[0, 0, 0]} />
				<Suspense fallback={null}>
					<Text3D position={[-1, -1, 1]} curveSegments={32} bevelEnabled bevelSize={0.04} bevelThickness={0.1} height={0.5} lineHeight={0.5} letterSpacing={-0.06} rotation={[0, -5, 0]} size={0.6} font="/assets/Inter_Bold.json">
						{`After\nThought`}
						<meshStandardMaterial attach="material" metalness={1.0} roughness={0} />
					</Text3D>
				</Suspense>
				<Suspense fallback={null}>
					<Model url="/assets/ape_astronaut.glb" position={[-0.2, -0.3, -1.4]} scale={1.5} rotation={[0, 6, 0]} />
				</Suspense>
				<Suspense fallback={null}>
					<Model url="/assets/cline.glb" position={[-1, -0.1, 0.5]} scale={3} rotation={[0, 2, 0]} />
				</Suspense>
				<Suspense fallback={null}>
					<Model url="/assets/discord_logo.glb" position={[-1, 0.2, 0.5]} scale={0.0009} rotation={[0, 2, 0]} isClickable onClick={handleModelClick} onPointerOver={handlePointerOver} onPointerOut={handlePointerOut} />
				</Suspense>
			</Canvas>
			{tooltip.show && (
				<div style={{
					position: 'absolute',
						left: `${tooltip.x}px`,
						top: `${tooltip.y}px`,
						background: '#000', // Light grey background
						border: '1px solid #ddd', // Light grey border
						borderRadius: '4px', // Rounded corners
						padding: '8px', // Space inside the tooltip
						boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', // Subtle shadow
						color: '#fff', // Text color
						fontSize: '18px', // Text size
						zIndex: 1000, // Ensure it's above other elements
						pointerEvents: 'none', // Prevents the tooltip from blocking pointer events
				}}>
					{tooltip.text}
				</div>
			)}
		</>
	);
}
